import Screen from '../components/Screen'
import { PageProps } from 'gatsby'

const Contact = (props: PageProps) => {
  return (
    <Screen {...props}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-2/4 lg:pr-12 pt-6">
          <h2 className="h2-medium divider pb-4 mb-4 mt-0">Kontakt</h2>
          <p className="p1-medium mb-0">Sbor dobrovolných hasičů Lipovka</p>
          <p className="p1 mt-2 mb-0">Lipovka 19</p>
          <p className="p1  mt-2 mb-0">Rychnov nad Kněžnou 516 01</p>
          <p className="p1 p1-main underline mt-2">sdhlipovka@seznam.cz</p>
        </div>
        <div className="w-full lg:w-2/4  lg:mt-0 mt-12">
          <div className="bg-zinc-200 px-6 lg:px-12 pb-8 pt-6">
            <h2 className="h2-medium divider-dark pb-4 mb-4 mt-0">
              Kde nás najdete
            </h2>
            <div>
              <iframe
                style={{ border: 'none' }}
                src="https://frame.mapy.cz/s/jusojufeme"
                className="w-full h-96"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Screen>
  )
}

export default Contact
